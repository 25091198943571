<template>
<v-row>
  <v-col cols="12" md="2">
    <v-text-field label="* Código"
                  dense
                  :rules="[
                      this.$validators.number.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 5)
                      ]"
                  v-model.trim="value.cdAlmoxarifado"/>
  </v-col>
  <v-col cols="12" sm="10">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                           v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 200)
                  ]"
                  v-model.trim="value.descricao"/>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: "AlmoxarifadoForm",
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>